import React, { Component } from "react";
import { ReactBnbGallery } from "react-bnb-gallery";
import axios from "axios";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { TIcon3, Icon1, Icon2, EmailIcon, PhoneIcon, LinkIcon } from "../../assets";
import GoogleMapReact from "google-map-react";
import { OrangePin } from "../../assets";
import { withStyles } from "@material-ui/core/styles";
import "../../assets/css/componentSpecificCss/kidspreview.css";
import CategoryTags from "../../components/front/CategoryTags";
import AgeRangeTag from "../../components/front/AgeRangeTag";
import GeoCode from "react-geocode";
import {
  Outdoors,
  Faith,
  Arts,
  Mixed,
  Athletics,
  STEM,
  Theatre,
  Creative,
  Dance,
  Exercise,
  SpecialNeeds,
  LanguageCulture,
  Drama,
  DaycarePreschool,
  Camps,
  Music,
  Cooking,
  AfterSchool,
  Academics,
  Leadership,
  Playgrounds,
  Tutoring,
  LifeSkills
} from "../../assets";
const styles = {
  plan: {
    transition: "all 300ms",
    "&:hover": {
      transform: "scale(1.001) translateY(-3px)",
      boxShadow: "0 2px 15px 0 rgba(204,204,204,0.5)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  tabRoot: {
    color: "gray !important",
    fontFamily: "Montserrat,sans-serif !important",
    fontWeight: "400 !important",
    fontSize: "1.1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#ff6663",
    height: "3px",
    marginTop: "20px"
  },
  input: {
    height: "3em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  textArea: {
    height: "6em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  datePicker: {
    height: "3em",
    border: "1px solid #E8E8E8",
    width: "100%",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  tabsHeadings: {
    height: "50px",
    minHeight: "40px",
    paddingLeft: "15px",
    borderTop: "1px solid gainsboro",
    borderBottom: "1px solid gainsboro"
  },
  subHeadings: {
    paddingLeft: "17px",
    display: "flex",
    alignItems: "center",
    "& .MuiTabs-flexContainer": {
      height: "50px"
    },
    "& .PreviewPartner-tabIndicator-5": {
      backgroundColor: "black"
    }
  }
};

GeoCode.setRegion("ca");
GeoCode.setLanguage("en");
GeoCode.setApiKey("");
class PreviewKids extends Component {
  constructor(props) {
    super(props);
    this.sortDays = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6
    };
    this.state = {
      galleryShown: false,
      active: "Programs",
      description: "",
      covid19: false,
      hours: [-1, -1],
      article: null,
      data: { target_ages: [], program_types: [], gallery: [] },
      events: [],
      memberships: [],
      programs: [],
      parties: [],
      other_locations: [],
      Registration: {},
      main_tags: [],
      gifts: [],
      tags: [],
      gallery: [],
      news: [],
      forms: [],
      similarPrograms: [],
      // displayDays: [],
      expandOperationHours: false,
      image_gifts: [
        "partners/Gift Cards/zeus-01.png",
        "partners/Gift Cards/zeus-02.png",
        "partners/Gift Cards/zeus-03.png",
        "partners/Gift Cards/zeus-04.png",
        "partners/Gift Cards/zeus-05.png",
        "partners/Gift Cards/zeus-06.png",
        "partners/Gift Cards/zeus-07.png",
        "partners/Gift Cards/zeus-08.png"
      ],
      formData: {},
      selectedForm: 0,
      selectedProgram: 0,
      sublinks: {
        Programs: [
          "Recurring",
          "Semester",
          "Camps",
          "Workshops",
          "Classes",
          "Online Virtual"
        ],
        Forms: [
          "Registration",
          "Applications",
          "Appointments",
          "Waitlists",
          "Contact Us"
        ]
      }
    };
  }
  setIcons() {
    const st = this.state;

    // {
    //   icon:
    //     "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/megaphone.png",
    //   title: "Operating Days",
    //   content: `${
    //     st.days_of_week
    //       ? Object.keys(st.days_of_week)
    //           .sort((a, b) => (this.sortDays[a] > this.sortDays[b] ? 1 : -1))
    //           .join(", ")
    //       : "--"
    //   }`
    // },
    return [
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/megaphone.png",
        title: "Reopening Plan",
        content: `${this.state.reopening}`
      },
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/graph.png",
        title: "Category",
        content: `${st.program_structures}`
      },
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/browser.png",
        title: "Website",
        content: `${st.website}`,
        hide: true
      },
      // {
      //   icon:
      //     "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/clock.png",
      //   title: "Hours",
      //   content:
      //     st.hours[0] === -1
      //       ? "--"
      //       : `${parseInt(st.hours[0] < 13 ? st.hours[0] : st.hours[0] - 12)}:${
      //           st.hours[0] / parseInt(st.hours[0]) > 1 ? "30" : "00"
      //         } ${st.hours[0] < 11.5 ? "A.M." : "P.M."} - ${parseInt(
      //           st.hours[1] < 13 ? st.hours[1] : st.hours[1] - 12
      //         )}:${st.hours[1] / parseInt(st.hours[1]) > 1 ? "30" : "00"} ${
      //           st.hours[1] > 11.5 ? "P.M." : "A.M."
      //         }`
      // },
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/printer.png",
        title: "Format",
        content: `${st.format}`
      },
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/smart-watch.png",
        title: "Phone",
        content: `${st.phone1}`,
        hide: true
      },
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/theatre.png",
        title: "Age Range",
        content: `${st.age_range}`
      },
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/map-.png",
        title: "Locations",
        content: 1 + this.state.other_locations.length
      },
      {
        icon: "https://dreamschools-files.s3.us-east-2.amazonaws.com/partners/Icons/letter.png",
        title: "Email",
        content: `${st.email}`,
        hide: true
      }
    ];
  }
  async componentDidMount() {
    const preview = this.props.match.params.preview || false;
    const slug = this.props.match.params.slug;
    const ep = `${process.env.REACT_APP_API}/frnt/program/kidget/${slug}/${preview}`;
    const res = await axios.get(ep);

    if (res.data.success) {
      // const days = Object.keys(res.data.data.days_of_week);
      // const DaysOfWeek = ['Monday', "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].filter(e => e == days.filter(f => f == e)[0]);
      this.setState({
        ...res.data.data,
        covid19: false,
        // displayDays: DaysOfWeek
      });

      const age_range = res.data.data.age_range.split(",").map(age => age.trim());
      console.log(age_range);
      const age_mappings = {};
      age_range.forEach(age => {
        if (!age_mappings[age.split(" ")[0]]) 
          age_mappings[age.split(" ")[0]] = age;
      });

      this.setState({
        age_range: Object.values(age_mappings).sort((a, b) => {
          let aVal = parseInt(a.split(" ")[1][1]);
          let bVal = parseInt(b.split(" ")[1][1]);
          if (a.split(" ")[0] === "Youth") {
            aVal = 13;
          } else if (a.split(" ")[0] === "Mixed") {
            aVal = Infinity;
          } else if (a.split(" ")[0] === "All") {
            aVal = Infinity;
          }
          if (b.split(" ")[0] === "Youth"){
            bVal = 13;
          } else if (b.split(" ")[0] === "Mixed") {
            bVal = Infinity;
          } else if (b.split(" ")[0] === "All") {
            bVal= Infinity;
          }

          return aVal - bVal;
        }).join(',')
      });
    }

    if (!preview) {
      const ep2 = `${process.env.REACT_APP_API}/frnt/program/getsimilar/${slug}`;
      const results = await axios.get(ep2);
      
      if (results.data.success) {
        this.setState({
          similarPrograms: results.data.data
        });
      }
    }
  }

  displayHours = (fromHours, toHours) => {
    if (fromHours === -1) {
      return "-";
    } else {
      return (
        <div>
          {parseInt(fromHours) > 12
            ? parseInt(fromHours) - 12
            : parseInt(fromHours)}
          :{fromHours / parseInt(fromHours) > 1 ? "30" : "00"}{" "}
          {fromHours < 11.5 ? "AM" : "PM"} -{" "}
          {parseInt(toHours) > 12 ? parseInt(toHours) - 12 : parseInt(toHours)}:
          {toHours / parseInt(toHours) > 1 ? "30" : "00"}{" "}
          {toHours > 11.5 ? "PM" : "AM"}
        </div>
      );
    }
  };

  render() {
    if (!this.state.days_of_week) {
      return null;
    }
    const photoMatch = {
      Outdoors: Outdoors,
      Faith: Faith,
      Arts: Arts,
      Music: Music,
      Mixed: Mixed,
      Athletics: Athletics,
      STEM: STEM,
      Theatre: Theatre,
      Creative: Creative,
      Dance: Dance,
      Leadership: Leadership,
      "Life Skills": LifeSkills,
      Exercise: Exercise,
      "After School": AfterSchool,
      "Before & After School": AfterSchool,
      Academics,
      "Special Needs": SpecialNeeds,
      Drama: Drama,
      Camps,
      Cooking,
      "Daycare / Preschool": DaycarePreschool,
      "Language / Culture": LanguageCulture,
      Playgrounds,
      Tutoring
    };
    console.log(this.state);
    return (
      <>
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={this.state.gallery.map(
            e => `${process.env.REACT_APP_DS_FILES_S3 + "/" + e}`
          )}
          onClose={_ => this.setState({ galleryShown: false })}
        />
        <div
          className="container-fluid header-img"
          style={{
            backgroundImage: `url(https://dreamschools-files.s3-us-east-2.amazonaws.com/partners/Banners/Banner3.jpg)`,
            backgroundPosition: "50%",
            backgroundBlendMode: "multiply",
            backgroundSize: "cover",
            backgroundColor: "rgba(0,0,255,.2)"
          }}
        ></div>

        <div className="top-content-kids">
          <div className="content-display-kids">
            <div className="left-content-kids">
              <div className="previewdivs">
                <div style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <h2 style={{ fontSize: "37.5px" }} className="title-kids">
                    {this.state.organization}{" "}
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexGrow: 1,
                      marginLeft: "20px"
                    }}
                  >
                    <div className="verification-container">
                      <span style={{backgroundColor: this.state.isLive ? "#00962D" : "#fcb103"}} className="spanview">
                        {this.state.isLive
                          ? "Verified"
                          : "Verification Pending"}
                      </span>
                    </div>

                    <div className="icon-btns">
                      {this.state.twitter && (
                        <div className="button-list">
                          <div
                            className="twitterbcg"
                            onClick={_ =>
                              window.open(
                                this.state.twitter.includes("http://") ||
                                  this.state.twitter.includes("https://")
                                  ? this.state.twitter
                                  : "http://" + this.state.twitter,
                                "_blank"
                              )
                            }
                          ></div>
                        </div>
                      )}
                      {this.state.instagram && (
                        <div className="button-list">
                          <div
                            className="igbcg"
                            onClick={_ =>
                              window.open(
                                this.state.instagram.includes("http://") ||
                                  this.state.instagram.includes("https://")
                                  ? this.state.instagram
                                  : "http://" + this.state.instagram,
                                "_blank"
                              )
                            }
                          ></div>
                        </div>
                      )}
                      {this.state.facebook && (
                        <div className="button-list">
                          <div
                            className="fbbcg"
                            onClick={_ =>
                              window.open(
                                this.state.facebook.includes("http://") ||
                                  this.state.facebook.includes("https://")
                                  ? this.state.facebook
                                  : "http://" + this.state.facebook,
                                "_blank"
                              )
                            }
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                {this.state.main_tags.concat(this.state.tags).map(tag =>
                  <CategoryTags category={typeof tag === "string" ? JSON.parse(tag).name : tag.name} />
                )}
              </div>
              <div style={{ display: "block" }}>
                {this.state.description.split("\n").map(e => (
                  <p className="kids-program-description" style={{ fontFamily: "Open Sans, sans-serif", fontSize: "23px" }}>{e}</p>
                ))}
              </div>
              <div>
                <div className="galwdt">
                  <h3
                    id="gallery-title"
                    style={{
                      margin: 0,
                      textAlign: "left",
                      marginBottom: "5px"
                    }}
                    className="sub-title"
                  >
                    Gallery
                  </h3>
                  <div style={{ display: "flex", overflow: "scroll", width: "100%"}}>
                    {this.state.gallery.length > 0 
                      ? this.state.gallery.map((e, index) => (
                      <div>
                        <div
                          onClick={_ => this.setState({ galleryShown: true })}
                          className="new-gallery-img"
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${e})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            transform: `rotate(${this.state[`gallery_image_${index}`]
                              }deg)`
                          }}
                        />
                      </div>
                    ))
                    : <div
                  >
                    <img
                      className="new-gallery-img"
                      src={`${photoMatch[this.state.image] || process.env.REACT_APP_DS_FILES_S3 + "/" + this.state.image}`}
                    />
                  </div>
                  }
                  </div>
                </div>
              </div>
            </div>
            <div className="right-content-kids">
              <h3
                id="program-details-title"
                style={{
                  margin: 0,
                  textAlign: "left",
                  marginBottom: "0px"
                }}
                className=" sub-title"
              >
                Program Details
              </h3>

              <div className="contacts-wrapper">
                <div>
                  <img src={EmailIcon} />
                  <span>{this.state.email} </span>
                </div>
                <div>
                  <img src={PhoneIcon} />
                  <span>{this.state.phone1} </span>
                </div>
                <div style={{width: "fit-content", cursor: "pointer"}} onClick={() => {
                  if (this.state.website.includes("http")) {
                    window.open(this.state.website);
                  } else {
                    window.open("https://" + this.state.website);
                  }
                }}>
                  <img src={LinkIcon} />
                  <span style={{color: "blue"}}>{this.state.website}</span>
                </div>
              </div>

              <div style={{ marginTop: "18px", fontSize: "18px", fontWeight: "bold" }}>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                  <div style={{ marginRight: "15px" }}>Age Range:</div>
                  {this.state.age_range.split(",").map(age =>
                    <AgeRangeTag ageRange={age.trim()} />
                  )}
                </div>
                <div>
                  Format: <span style={{ fontWeight: "normal", paddingLeft: "3px" }}> {this.state.format}</span>
                </div>
              </div>

              <div style={{
                marginTop: "15px",
                marginBottom: "15px",
                height: "2px",
                backgroundColor: "#929292",
                width: "100%"
              }} />
              
              <h3 className="sub-title">Hours of Operation</h3>
              {this.state.operational_days.map(e => {
                const ele = typeof e === "string" ? JSON.parse(e) : e;

                return (
                  <div className="week-timings">
                    <div className="daily-timings">
                      <strong className="days-of-week">
                        {ele.day}
                      </strong>
                      <span className="days-timings">
                        {this.displayHours(ele.fromHours, ele.toHours)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="content-area-kids">
          <div className="top-content-kids">

            <div className="content-display-kids" style={{ display: "block" }}>
              <div
                style={{ height: "250px", width: "100%", marginBottom: "6em" }}
              >
                <h3 id="location-title" className="sub-title">
                  Location
                </h3>
                <span>{this.state.address}</span>
                {this.state.lat && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAVaN8qvELLoX3nYdqFC91GqbxXLcV-cig"
                    }}
                    defaultZoom={9}
                    defaultCenter={{
                      lat: parseFloat(this.state.lat),
                      lng: parseFloat(this.state.long)
                    }} // Default toronto lat: 43.695572, lng: -79.415605
                    options={{ gestureHandling: "greedy" }}
                  >
                    <div
                      lat={this.state.lat}
                      lng={this.state.long}
                      style={{
                        backgroundImage: `url(${OrangePin})`,
                        backgroundSize: "cover",
                        height: 30,
                        width: 22,
                        cursor: "pointer",
                        zIndex: 10,
                        position: "relative",
                        bottom: 15,
                        right: 15
                      }}
                    ></div>
                    {this.state.other_locations.map(e => (
                      <div
                        lat={e.lat}
                        lng={e.lng}
                        style={{
                          backgroundImage: `url(${OrangePin})`,
                          backgroundSize: "cover",
                          height: 30,
                          width: 22,
                          cursor: "pointer",
                          zIndex: 10,
                          position: "relative",
                          bottom: 15,
                          right: 15
                        }}
                      ></div>
                    ))}
                  </GoogleMapReact>
                )}
              </div>
            </div>
          </div>
        </div>
        {!this.props.match.params.preview && <div style={{
            marginTop: "30px",
            marginBottom: "15px",
            height: "2px",
            backgroundColor: "#929292",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto"
          }} />}
          {!this.props.match.params.preview && 
            <div className="content-display-kids">
              <div className="explore-programs-wrapper">
                <h3 className="sub-title">
                  Explore Similar Programs
                </h3>
                <div className="kids-programs-panel-wrapper">
                  <div className="kids-programs-panel">
                    {
                      this.state.similarPrograms.map(program => {
                        return (
                          <div 
                            className="kid-program-card"
                            onClick={() => {
                              window.location.replace(
                                `${process.env.REACT_APP_URL}/kids/${program.slug}`
                              );
                            }}
                          >
                            <img style={{height: "200px", width: "200px"}} src={`${program.gallery.length > 0 ? `${process.env.REACT_APP_DS_FILES_S3}/${program.gallery[0]}` : `${photoMatch[program.image] || `${process.env.REACT_APP_DS_FILES_S3}/${program.image}`}`}`}/>
                            <div>
                              {program.program_name}
                            </div>
                          </div>
                        )
                      })  
                    }
                  </div>
                </div>
              </div>
            </div>
          }
      </>
    );
  }
}

export default withStyles(styles)(PreviewKids);
