import React from "react";
import "../../assets/css/componentSpecificCss/ageRangeTag.css";

const AgeRangeTag = ({ ageRange, fontSize, paddingTop, paddingBottom }) => {
  const colours = [
    {
      ageRange: "Infant",
      colour: "#9B61C1"
    },
    {
      ageRange: "Toddler",
      colour: "#5086CC"
    },
    {
      ageRange: "Preschool",
      colour: "#61C974"
    },
    {
      ageRange: "Kindergarten",
      colour: "#C0B42B"
    },
    {
      ageRange: "Mixed",
      colour: "#908F8F"
    },
    {
      ageRange: "Gradeschool",
      colour: "#EC7E00"
    },
    {
      ageRange: "Youth",
      colour: "#E3492D"
    },
    {
      ageRange: "All",
      colour: "#908F8F"
    },
    {
      ageRange: "Adult",
      colour: "#908F8F"
    }
  ]
  const colour = colours.filter(colour =>  colour.ageRange === ageRange.split(" ")[0])[0];
  if (!colour) {
    return <div/>;
  }
  
  return (
    <div className="age-range-tag" style={{backgroundColor: colour.colour, fontSize, paddingTop, paddingBottom}}>
      { ageRange }
    </div>
  )
};

export default AgeRangeTag;