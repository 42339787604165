import React from "reactn";
import { LiveView1, LiveView2, OrangePin } from "../../assets";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import Checkout from "./checkout";
import SignIn from "../../components/modals/SignInModal";
import CartModal from "../../components/parents/CartModal";
import { withStyles } from "@material-ui/core/styles";
import "../../assets/css/componentSpecificCss/liveSpecificPages.css";
import { toast } from "react-toastify";
import SignUpNewModal from "../../components/modals/SignUpNewModal";
import { DateTime } from "luxon";
import {
  getFormattedDateTimeRange,
  testIsInteger
} from "./SpecificPageUtils/utils";
import { withRouter } from "react-router-dom";
import { ReactBnbGallery } from "react-bnb-gallery";
import Radio from "@material-ui/core/Radio";
import SummarySubTotalParties from "./SpecificPageUtils/SummarySubTotalParties";
import ShareButtonModal from "../../components/modals/ShareButtonModal";
import NoDataAvailable from "../../components/front/partner/NoDataAvailable";
import qs from "qs";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

class SpecificParty extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      textShown: false,
      mapShown: false,
      gallery: [],
      party: {
        party_end: null,
        party_start: null,
        target_ages: [0, 0],
        tickets: [],
        other_locations: []
      },
      selected: null,
      checkout: false,
      isOpenSignUpModal: false,
      showDescription: true,
      showCancellationPolicy: true,
      showLocation: true,
      isSkipForm: false,
      orgSlug: "",
      customers: [],
      isTicketFull: false,
      email: "",
      isEnableWaitlist: false,
      checkoutType: "Regular",
      showCart: false,
      disableAddToCart: false
    };

    this.updateScreenSize = this.updateScreenSize.bind(this);
  }

  async componentDidMount() {
    console.log("PROPS: ", this.props);
    this.setGlobal({ loading: true });
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
    const ep = `${process.env.REACT_APP_API}/partners/search/party/${this.props.match.params.id}`;
    const results = await axios.get(ep);

    let gallery;
    if (this.props.match.params.slug) {
      const gallery_ep = `${process.env.REACT_APP_API}/partners/search/profile/${this.props.match.params.slug}`;
      gallery = await axios.get(gallery_ep);
    }

    if (!results.data.success) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }

    this.setState({ orgSlug: results.data.data.slug });

    if (this.global.dsUser) {
      const param = this.props.match.params.id;
      const customers_ep = `${process.env.REACT_APP_API}/partners/parties/customers/${param}`;
      const customers = await axios.get(customers_ep);

      if (customers.data.success) {
        this.setState({
          customers: customers.data.data
        });
      }
    }

    if (results.data.success && results.data.data.isArchived) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }

    if (this.props.location.query && this.props.location.query.plan) {
      this.setState({
        selected: parseInt(this.props.location.query.plan),
        isSkipForm: this.props.location.query.isSkipForm == "true"
      });
    } else if (this.props.history.location.query && this.props.history.location.query.plan) {
      this.setState({
        selected: parseInt(this.props.history.location.query.plan),
        isSkipForm: this.props.history.location.query.isSkipForm == "true"
      });
    }

    if (this.props.location.query && this.props.location.query.visible && this.props.location.query.visible === "true") {
      this.setGlobal({
        footer: false
      })
    }

    // if (
    //   this.props.location.search &&
    //   this.props.location.search.includes("visible=true")
    // ) {
    //   const planNumber = parseInt(
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   console.log(
    //     "plan number",
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   this.setState({ selected: planNumber });
    // }

    if (!this.props.location.query) {
      this.setState({
        isSkipForm: this.props.history.location.query.isSkipForm == "true"
      });
    }

    this.setState({
      party: results.data.data,
      gallery: gallery.data?.data?.data?.gallery ? gallery.data?.data?.data?.gallery : [],
      isEnableWaitlist: results.data.data.enable_waitlist
    });
    this.setGlobal({ loading: false });
  }

  getLocation(locations) {
    const data = locations.filter(e => e.address === this.state.party.address1);
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={this.state.party.main_lat}
        lng={this.state.party.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  }

  updateScreenSize() {
    this.setState({
      showDescription: window.innerWidth > 600,
      showCancellationPolicy: window.innerWidth > 600,
      showLocation: window.innerWidth > 600
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  openGallery = () => {
    this.setState({
      galleryShown: !this.state.galleryShown
    });
  };

  isTicketFull = ticket => {
    const ticketQuantity = parseInt(
      JSON.parse(this.state.party.tickets[ticket]).ticket_quantity
    );

    const ticketStripe = JSON.parse(
      this.state.party.tickets[ticket]
    ).ticket_stripe;

    let count = 0;

    this.state.customers.forEach(item =>
      item.status_stage === "Accepted_Paid" &&
        JSON.parse(item.tickets[item.plan_id]).ticket_stripe === ticketStripe
        ? count++
        : false
    );
    console.log("TICKET FULL", count, ticketQuantity);
    if (count >= ticketQuantity) {
      this.setState({ isTicketFull: true });
    } else {
      this.setState({ isTicketFull: false });
    }
  };

  render() {
    console.log("THE STATE", this.state);
    //=================================================================================================
    const failedLocalStorageDsUserToken = () => {
      try {
        if (localStorage.getItem("ds_token")) {
          return false;
        }
        return false;
      } catch {
        console.log("FAILED TO FETCH FROM LOCAL STORAGE");
        return true;
      }
    };

    const isPlanSelected = () =>
      this.state.selected !== null && this.state.selected !== undefined; //do not use -1 to represent a non-selection. And id=0 is a valid price plan!

    const getAccountType = user => user.accountType;
    const isUserParent = user => getAccountType(user) === "Parent";
    const isUserVolunteer = user => getAccountType(user) === "Volunteer";
    const isUserStudent = user => getAccountType(user) === "Student";
    const canUserVisitCheckout = user =>
      isUserParent(user) || isUserVolunteer(user) || isUserStudent(user);
    const checkUserAndLoggedInAccountType = () =>
      this.global.dsUser && canUserVisitCheckout(this.global.dsUser);

    //===========================================================================
    // click handler for Enroll Now button
    //===========================================================================
    const onEnrollNow = async () => {
      if (!isPlanSelected()) {
        return toast.error(`Please select a plan!`);
      }
      const planId = this.state.selected;

      if (failedLocalStorageDsUserToken()) {
        console.log(
          "onEnrollNow: Failed to access local storage. Invoking window.open()."
        );
        window.open(
          `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/party/${this.state.party.id}?plan=${planId}`
        );
        return;
      }

      this.setState({ checkoutType: "Regular" });

      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
        if (checkUserAndLoggedInAccountType()) {
          return this.setState({ checkout: true });
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
      if (!localStorage.getItem("ds_token")) {
        return this.setState({ isOpenSignUpModal: true });
      } else {
        if (checkUserAndLoggedInAccountType()) {
          return this.setState({ checkout: true });
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
    };

    //===========================================================================
    // click handler for Add to Cart button
    //===========================================================================
    const onAddToCart = async () => {
      if (!isPlanSelected()) {
        return toast.error(`Please select a plan!`);
      }
      const planId = this.state.selected;

      if (failedLocalStorageDsUserToken()) {
        console.log(
          "onAddToCart: Failed to access local storage. Invoking window.open()."
        );
        window.open(
          `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/party/${this.state.party.id}?plan=${planId}`
        );
        return;
      }

      this.setState({ checkoutType: "Cart" });

      const data = {
        partnerId: this.state.party.partnerId,
        productId: this.state.party.id,
        planId: planId,
        quantity: 1,
        productTable: "partner_party"
      };

      const addToCart = async () => {
        await this.dispatch.getCart({
          partnerSlug: this.props.match.params.slug
        });
        const ep = `${process.env.REACT_APP_API}/par/cart`;
        const res = await axios.put(ep, data);
        if (res.data.success) {
          toast.success("Added to cart!");
          this.setState({ showCart: true });
          console.log("this.global.cart:", this.global.cart);
        } else {
          toast.error(res.data.message);
        }
      };

      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
        if (checkUserAndLoggedInAccountType()) {
          await addToCart();
          return;
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
      if (!localStorage.getItem("ds_token")) {
        return this.setState({ isOpenSignUpModal: true });
      } else {
        if (checkUserAndLoggedInAccountType()) {
          await addToCart();
          return;
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
    };
    //=================================================================================================

    console.assert(
      this.state.party.timezone,
      "timezone not provided for partner/educator!"
    );

    const queryParams = this.props.location.search
      ? this.props.location.query ||
      this.props.history.location.query ||
      qs.parse(this.props.location.search.substr(1))
      : {};
    //below, we're taking extra precautions for invites - because some may plans may be active=false.
    const onlyShowOnePlanEvenIfInactive =
      queryParams.visible && queryParams.visible !== "false";
    const urlPreSelectedPlan = testIsInteger(queryParams.plan)
      ? parseInt(queryParams.plan)
      : undefined;

    const partyStart = DateTime.fromISO(this.state.party.party_start);
    const partyEnd = DateTime.fromISO(this.state.party.party_end);
    const rezonedStart = partyStart.setZone(this.state.party.timezone);
    const rezonedEnd = partyEnd.setZone(this.state.party.timezone);
    const currTime = DateTime.local();
    const rezonedCurrTIme = currTime.setZone(this.state.party.timezone);

    console.log(
      "REZONED TIME",
      currTime.toISO(),
      rezonedCurrTIme.toISO(),
      partyStart.toISO(),
      rezonedStart.toISO()
    );
    /* Parties are a single block of time.
       e.g. Rock Concert: Jan 20, 9pm to Jan 21, 1am.   <-- it starts late, and rolls over to the next day.
            Not, Jan 20-Jan 21, 9pm to 1am.
       Representations:
        Jan 20, 2021 - 9pm to 11pm EST                    <-- start and end time are same day.
        Jan 20, 2021, 9:00pm - Jan 21, 2021, 1:00am EST   <-- start and end time are different days
    */

    let formattedDateTimeRange = getFormattedDateTimeRange(
      rezonedStart,
      rezonedEnd
    );
    console.log(
      "TIME RANGE",
      new Date(rezonedStart),
      "REZONED CURR TIME",
      new Date(rezonedCurrTIme)
    );

    let noPlansAvailableMessage;

    const partyEndDatePassed = this.state.party.continuous_registration
      ? new Date(rezonedEnd.ts) < new Date(rezonedCurrTIme) &&
      !onlyShowOnePlanEvenIfInactive
      : new Date(rezonedStart.ts) < new Date(rezonedCurrTIme) &&
      !onlyShowOnePlanEvenIfInactive;

    if (partyEndDatePassed) {
      if (this.state.party.continuous_registration) {
        noPlansAvailableMessage = "- End date has passed";
      } else {
        noPlansAvailableMessage = "- Start date has passed";
      }
    }

    if (!this.state.party["main_lat"]) {
      return null;
    }

    if (this.state.orgSlug !== this.props.match.params.slug) {
      return null;
    }

    console.log(
      "failedLocalStorageDsUserToken",
      failedLocalStorageDsUserToken()
    );
    // console.log("this.state", this.state);
    // console.log("this.props", this.props);

    if (this.state.party?.published === false && (!this.props.location.query?.email || !this.props.location.query?.visible === "true")) {
      return <NoDataAvailable message="Party Unavailable" />
    }

    return this.state.checkout ? (
      <Checkout
        form={this.state.party.formId}
        program={this.state.party}
        selected={this.state.selected}
        organization={this.state.party.organization_title}
        close={_ => this.setState({ checkout: false })}
        type={"Party"}
        isSkipForm={this.state.isSkipForm}
        planWaitlist={this.state.isEnableWaitlist}
      />
    ) : (
      <div className="containerall" style={{ overflowX: "hidden" }}>
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={this.state.gallery.map(
            e => `${process.env.REACT_APP_DS_FILES_S3 + "/" + e}`
          )}
          onClose={this.openGallery}
        />
        {this.state.showCart && (
          <CartModal
            partnerSlug={this.props.match.params.slug}
            animation={true}
            hideCart={() => {
              this.setState({ showCart: false });
            }}
            toggleAddToCart={() => {
              this.setState({ disableAddToCart: !this.state.disableAddToCart });
            }}
          />
        )}
        {this.state.signIn && (
          <SignIn
            onClose={_ => this.setState({ signIn: false, email: "" })}
            program={this.state.party}
            checkout={_ => {
              if (this.state.checkoutType === "Cart") {
                this.setState({ signIn: false });
                onAddToCart();
              } else {
                this.setState({ signIn: false, checkout: true });
              }
            }}
            openSignUpModal={_ => this.setState({ isOpenSignUpModal: true })}
            email={this.state.email}
          />
        )}
        {this.state.isOpenSignUpModal && (
          <SignUpNewModal
            onClose={_ => this.setState({ isOpenSignUpModal: false })}
            program={this.state.party}
            checkout={_ => {
              if (this.state.checkoutType === "Cart") {
                this.setState({ isOpenSignUpModal: false });
                onAddToCart();
              } else {
                this.setState({ isOpenSignUpModal: false, checkout: true });
              }
            }}
            openSignInModal={_ => this.setState({ signIn: true })}
            setEmail={enteredEmail => {
              this.setState({ email: enteredEmail });
            }}
          />
        )}
        {this.state.isOpenShareButtonModal && (
          <ShareButtonModal
            onClose={() => {
              this.setState({
                ...this.state,
                isOpenShareButtonModal: false
              })
              console.log("HERE", this.props.history.location.pathname)
              console.log((process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
                + this.props.history.location.pathname)
            }}
            url={(process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
              + this.props.history.location.pathname}
            // quote={"HELLO"}
            hashtag={"#DreamSchools"}
          // description={"DESCRIPTION STUFF"}
          />
        )}
        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">
              {this.state.party.party_title}{" "}
              {this.state.isEnableWaitlist && this.state.isTicketFull && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    On Waitlist
                  </span>
                  {")"}
                </span>
              )}
              {!this.state.isEnableWaitlist && this.state.isTicketFull && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    FULL
                  </span>
                  {")"}
                </span>
              )}
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                className="org-title"
                onClick={_ => {
                  this.props.location.pathname.includes("iframe")
                    ? this.props.history.push(
                      `/iframes/programs/${this.props.match.params.slug}`
                    )
                    : this.props.history.push(
                      `/programs/${this.props.match.params.slug}`
                    );
                }}
              >
                {this.state.party.organization_title}
              </h4>
              <div>
                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                  onClick={this.openGallery}
                >
                  Gallery
                </button>
                <button
                  className="partnerbutton"
                  style={{marginRight: "10px"}}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      isOpenShareButtonModal: true,
                    })
                  }}
                >
                  Share
                </button>
              </div>
            </div>
          </div>

          <div className="maincontainerdiv">
            <img
              className="imageprogram"
              alt="party"
              src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.party.party_image}`}
            />
            <div
              className="widgetdata"
              style={{
                textAlign: "center",
                marginLeft: "50px"
              }}
            >
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView1} alt="icon" className="icon-img" />
                <p className="icon-text">{formattedDateTimeRange}</p>
              </div>

              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView2} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {this.state.party.all_ages
                    ? "All Ages"
                    : `Ages: ${this.state.party.target_ages[0]} to${" "}
                  ${this.state.party.target_ages[1]}`}
                </p>
              </div>
            </div>
          </div>

          <div className="box-size">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h3 className="sub-title">Party Description</h3>
              {this.state.showDescription ? (
                <i
                  className="fas fa-chevron-up"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({
                      showDescription: !this.state.showDescription
                    })
                  }
                ></i>
              ) : (
                <i
                  className="fas fa-chevron-down"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({
                      showDescription: !this.state.showDescription
                    })
                  }
                ></i>
              )}
            </div>
            {this.state.showDescription && (
              <p
                // style={{
                //   fontWeight: "400",
                //   fontFamily: `"Open sans", sans-serif`,
                //   whiteSpace: "pre-line"
                // }}
                className="box-description-text"
              >
                {this.state.party.description}
              </p>
            )}
          </div>

          {this.state.party.show_cancellation_policy && (
            <div className="box-size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <h3 className="sub-title">Cancellation Policy</h3>
                {this.state.showCancellationPolicy ? (
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showCancellationPolicy:
                          !this.state.showCancellationPolicy
                      })
                    }
                  ></i>
                ) : (
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showCancellationPolicy:
                          !this.state.showCancellationPolicy
                      })
                    }
                  ></i>
                )}
              </div>
              {this.state.showCancellationPolicy && (
                <p className="box-description-text">
                  {this.state.party.cancellation_policy}
                </p>
              )}
            </div>
          )}

          <div className="box-size">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h3 className="sub-title">Location</h3>
              {this.state.showLocation ? (
                <i
                  className="fas fa-chevron-up"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({ showLocation: !this.state.showLocation })
                  }
                ></i>
              ) : (
                <i
                  className="fas fa-chevron-down"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({ showLocation: !this.state.showLocation })
                  }
                ></i>
              )}
            </div>
            {this.state.showLocation && (
              <div className="map-size box-description-text">
                <p>Address : {this.state.party.address1}</p>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAVaN8qvELLoX3nYdqFC91GqbxXLcV-cig"
                  }}
                  defaultZoom={9}
                  defaultCenter={{
                    lat: parseFloat(this.state.party.main_lat),
                    lng: parseFloat(this.state.party.main_long)
                  }}
                  options={{ gestureHandling: "greedy" }}
                >
                  {this.getLocation(this.state.party.other_locations)}
                </GoogleMapReact>
              </div>
            )}
          </div>
        </div>

        <div className="rightcontainerprogram">
          <h2 className="right-cont-name">Select Plan</h2>

          <div style={{ width: "80%", margin: "0 auto" }}>
            {partyEndDatePassed && (
              <div>
                <p>No plans available {noPlansAvailableMessage}</p>
              </div>
            )}
            {!partyEndDatePassed &&
              this.state.party.tickets.map((ele, key) => {
                const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                // if (onlyShowOnePlanEvenIfInactive) {
                //   return urlPreSelectedPlan === e.id;
                // }
                if (
                  (this.props.location.query &&
                    this.props.location.query.plan &&
                    this.props.location.query.visible) ||
                  (this.props.location.search &&
                    this.props.location.search.includes("plan=") &&
                    this.props.location.search.includes("visible=true"))
                ) {
                  if (parseInt(e.id) === this.state.selected) {
                    e.active = true;
                  } else {
                    e.active = false;
                  }
                }
                if (e.archived || e.active === false) {
                  return null;
                }
                return (
                  <div
                    className={
                      this.state.selected === key
                        ? this.props.classes.planSelected + " pricingcard"
                        : this.props.classes.plan + " pricingcard"
                    }
                    onClick={_ => {
                      this.setState({ selected: key });
                      this.isTicketFull(key);
                    }}
                    key={key}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={this.state.selected === key}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: this.props.classes.radio,
                          checked: this.props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <p className="pricingcard-name">
                        {e.ticket_name} <br />{" "}
                        <span className="pricingcard-days">
                          {e.ticket_status === "Paid"
                            ? "Standard"
                            : e.ticket_status}{" "}
                          Ticket
                        </span>
                        {
                          this.state.party.partyType === "Field Trip" && (e.destination || e.address) && 
                          <div className="pricingcard-days">Destination: {e?.destination?.address ? e.destination.address.split(",")[0] : e.address.split(",")[0]}</div>
                        }
                        {
                          this.state.party.partyType === "Private Event" && e.theme &&
                          <div className="pricingcard-days"> Theme: {e.theme} </div>
                        }
                      </p>
                    </div>

                    <div style={{ width: "50px" }}>
                      <p className="pricingcard-amount">
                        {e.ticket_price ? `$${e.ticket_price}` : "Free"}
                      </p>
                    </div>
                  </div>
                );
              })}

            {this.state.selected !== null && (
              <SummarySubTotalParties
                plans={this.state.party.tickets}
                selected={this.state.selected}
              />
            )}
            {(this.state.party.isCartDisabled && !partyEndDatePassed) ||
              this.state.isTicketFull ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: `10px`,
                    fontWeight: `bold`,
                    border: 0,
                    cursor:
                      (this.state.isTicketFull &&
                        !this.state.isEnableWaitlist) ||
                        partyEndDatePassed
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    (this.state.isTicketFull && !this.state.isEnableWaitlist) ||
                      partyEndDatePassed
                      ? true
                      : false
                  }
                  onClick={onEnrollNow}
                >
                  Register Here Now
                </button>
                {this.state.isTicketFull && (
                  <div>
                    <i className="bi bi-info-circle" /> The selected ticket is
                    full
                  </div>
                )}
              </div>
            ) : !partyEndDatePassed ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {/* <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    fontWeight: `bold`,
                    border: 0,
                    marginTop: "40px",
                    cursor:
                      this.state.disableAddToCart ||
                        (this.state.isTicketFull &&
                          !this.state.isEnableWaitlist) ||
                        partyEndDatePassed
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    this.state.disableAddToCart ||
                      (this.state.isTicketFull && !this.state.isEnableWaitlist) ||
                      partyEndDatePassed
                      ? true
                      : false
                  }
                  onClick={onAddToCart}
                >
                  Add to Cart
                </button>
                <span>-------- OR --------</span> */}
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(211,52,49)`,
                    color: `white`,
                    marginTop: `10px`,
                    fontWeight: `bold`,
                    border: 0,
                    cursor:
                      (this.state.isTicketFull &&
                        !this.state.isEnableWaitlist) ||
                        partyEndDatePassed
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    (this.state.isTicketFull && !this.state.isEnableWaitlist) ||
                      partyEndDatePassed
                      ? true
                      : false
                  }
                  onClick={onEnrollNow}
                >
                  Register Here Now
                </button>
                {this.state.isTicketFull && (
                  <div>
                    <i className="bi bi-info-circle" /> The selected ticket is
                    full
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(SpecificParty));
