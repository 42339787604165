import React from "reactn";
import { formTypes } from "../form";
import "../../../assets/css/componentSpecificCss/form.css";

class Form extends React.PureComponent {
  render() {
    const { fields, handleChange } = this.props;
    return (
      <div className="formbody">
        <form>
          {Object.keys(fields).map(field => {
            console.log("Form Field value: ", field);
            return fields[field].line ? (
              <hr key={field} style={fields[field].style}/>
            ) : (
              <div className="row" key={field}>
                {fields[field].fullWidth ? (
                  fields[field].textBefore ? (
                    <div className="col-12">
                      <h3>
                        {fields[field].textBefore}{" "}
                        {fields[field].required ? (
                          <span className="requiredField">*</span>
                        ) : (
                          ""
                        )}
                      </h3>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="col-5">
                    <h3>
                      {fields[field].name}
                      {fields[field].required ? (
                        <span className="requiredField">*</span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                )}
                <div
                  className={
                    fields[field].fullWidth ? "col-12 d-flex" : "col-7 d-flex"
                  }
                  style={{
                    alignItems: "flex-end",
                    background: fields[field].isDisabled ? "#f5f5f5" : "white"
                  }}
                >
                  <div
                    style={{
                      borderBottom:
                        fields[field].required &&
                        (!fields[field].val || !fields[field].val.length)
                          ? "1px solid red"
                          : "1px solid transparent"
                    }}
                    className="w-100"
                  >
                    {fields[field].customField ? (
                      fields[field].customField()
                    ) : fields[field].type === formTypes.TextArea ? (
                      <div
                        style={{
                          background:
                            fields[field].name === "Email"
                              ? "lightgrey"
                              : "white"
                        }}
                      >
                        {React.createElement(fields[field].type, {
                          ...fields[field],
                          isDisabled: fields[field]["isDisabled"],
                          onChange: handleChange,
                          onBlur: fields[field]["onBlur"],
                          valueOnly: true,
                          name: field,
                          maxLength: fields[field].maxLength,
                          value: fields[field].val,
                          rows: fields[field].rows,
                          options: fields[field].options,
                          autoComplete: "off"
                        })}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div>Min: 40 characters</div>
                          <div>{fields[field].val.length}</div>
                        </div>
                      </div>
                    ) : (
                      React.createElement(fields[field].type, {
                        ...fields[field],
                        isDisabled: fields[field]["isDisabled"],
                        onChange: handleChange,
                        onBlur: fields[field]["onBlur"],
                        valueOnly: true,
                        name: field,
                        maxLength: fields[field].maxLength,
                        value: fields[field].val,
                        rows: fields[field].rows,
                        options: fields[field].options,
                        autoComplete: "off",
                        className: "profile-input"
                      })
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </form>
      </div>
    );
  }
}

export default Form;
